<template>
  <BaseDialog :title="displayText.title" hideCancel :show-close="false" @confirm="onConfirm">
    <div style="padding-bottom: 40px">
      {{ displayText.content }}
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { i18n } from '@/plugins/i18n/i18n'
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'
export default defineComponent({
  name: 'LinkInvalidModal',
  components: {
    BaseDialog,
  },
  setup (props, { emit }) {
    const router = useRouter()
    const onConfirm = () => {
      router.push({ name: 'Login' })
    }

    const displayText = computed(() => {
      const text = {
        title: i18n.t('auth.dialog.resetPasswordLinkInvalid.title'),
        content: i18n.t('auth.dialog.resetPasswordLinkInvalid.content'),
      }
      return text
    })
    return { onConfirm, displayText }
  },
})
</script>

<style lang="postcss" scoped>

</style>
